import React from 'react'
import jQuery from 'jquery';
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import {
  ImageContainer,
  ShopImageWrapper,
  ProductInfos,
  NotAvailable,
  AddToCart,
  ProductWrapper,
  ProductInner
} from '../templates/catalogItemStyle'

import { handleModalBanner } from '../actions/modal'
import "../styles/magnify.css";

class CatalogItem extends React.Component {

  state = {
    loading: true,
    zoomInstance: null
  }

  componentDidMount() {
    const prismic = this.props.currentArtwork.document[0];
    const description = prismic.data.cadre.localFile
      ? `${prismic.data.type} - ${prismic.data.cadre.alt}`
      : prismic.data.type;

    const isFondAtelier = prismic.data.categories.filter((item) => {
      return item.category.uid === 'fonds-datelier';
    }).length > 0;
    
    const reduction = isFondAtelier ? 0 : 0;
    const price = reduction > 0 ? (
      parseInt(prismic.data.price) - parseInt(prismic.data.price) * 10 / 100
    ) : prismic.data.price

    this.data = {
      id: prismic.prismicId,
      uid: prismic.uid,
      title: prismic.data.title.text,
      description: description,
      amount: price,
      reduction: reduction
    }
    
    setTimeout(() => {
      this.setState({ loading: false })
    }, 3000);

    if (typeof this.props.elementId !== 'undefined'){
      if (typeof document !== 'undefined') {
        const _self = this;
        
        if (_self.props.zoomPosition) {
          jQuery(document).ready(function(){
            jQuery(`#${_self.props.elementId} img.xzoom`).xzoom({
              lensReverse: false,
              smoothScale: 10,
              position: _self.props.zoomPosition
            });
          })
        } else {
          jQuery(document).ready(function(){
            jQuery(`#${_self.props.elementId} img.xzoom`).xzoom({
              lensReverse: false,
              smoothScale: 10,
              position: (_self.props.id % 2 === 0 ? (_self.props.id > 1 ? 'left' : 'right') : 'left')
            });
          })
        }
        
      }
    }
  }

  render() {

    const {
      currentArtwork: { document: [{
        prismicId, data
      }] },
      count,
      cartItems,
      firebaseOrders,
      elementId,
      hideprice,
      hd,
      cadre,
      noPadding,
      responsiveHeight
    } = this.props;
    
    const isOrdered = firebaseOrders.length > 0 && firebaseOrders.filter(i => i === prismicId).length === 1
    
    if (typeof document !== 'undefined') {
      jQuery(document).ready(function () {
        var instance = jQuery(`#${elementId} img.xzoom`).data('xzoom');
        
        if (typeof instance !== 'undefined') {
          instance.eventunbind();
          jQuery(`#${elementId} img.xzoom`).click(function(event) {

            instance.eventleave = function(element) {
              element.on('click', function(event) {
                instance.closezoom();
              });
            }

            instance.eventscroll = function(element) {
              element.on('mousewheel DOMMouseScroll', function(event) {
                instance.xscroll(event);
              })
            }

            instance.eventmove = function(element) {
              element.on('mousemove', function(event) {
                instance.movezoom(event);
              })
            }

            instance.openzoom(event);
          })
        }
      });
    }
    
    const isFondAtelier = data.categories.filter((item) => {
      return item.category.uid === 'fonds-datelier';
    }).length > 0;
    
    return (
      <React.Fragment>
        <div 
          id={elementId}
          style={{
            visibility: this.state.loading ? 'hidden' : 'visible'
          }}
          className={`col-${count}`}
        >
          <ProductWrapper 
            className={count > 1 ? `col-${count}` : `${data.catalog_fullscreen ? 'col-full' : `col-1`}`} 
            style={{ 
              padding: noPadding ? '0' : '0 1rem',
            }}
          >
            <ProductInner className={data.catalog_fullscreen ? 'fullscreen' : 'default'}>
              {count > 1 ? (
                <React.Fragment>
                  <ImageContainer className="full">
                    <ShopImageWrapper>
                      <img 
                        className="xzoom"
                        src={data.cadre.localFile ? (
                          data.cadre.localFile.childImageSharp.fluid.src
                        ) : (
                          data.item.localFile.childImageSharp.fluid.src
                        )}
                        xoriginal={data.image_hd.localFile ? (
                          data.image_hd.localFile.childImageSharp.original.src
                        ) : (
                          data.item.localFile.childImageSharp.fluid.src
                        )}
                      />
                    </ShopImageWrapper>
                  </ImageContainer>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {
                    data.catalog_fullscreen ? (
                      <React.Fragment>
                        <ImageContainer className="full">
                          <ShopImageWrapper>
                            <img 
                              className="xzoom"
                              src={hd ? (
                                data.image_hd.localFile.childImageSharp.original.src
                              ) : (
                                data.item.localFile.childImageSharp.fluid.src
                              )}
                              xoriginal={data.image_hd.localFile ? (
                                data.image_hd.localFile.childImageSharp.original.src
                              ) : (
                                data.item.localFile.childImageSharp.fluid.src
                              )}
                            />
                          </ShopImageWrapper>
                        </ImageContainer>

                        {
                          !hideprice && (
                            <ProductInfos className={isOrdered ? "isOrdered" : ""}>
                              <div>
                                <h2>
                                  <div>{data.title.text}</div>
                                  {(JSON.parse(data.available) === false || isOrdered) && (
                                    <NotAvailable />
                                  )}
                                </h2>
                                <span className="type">{data.type}</span>
                                <span className="categories">
                                  <small>categories: </small>
                                  <small>
                                    <Link to={`/works/`}>#works</Link>
                                  </small>
                                  {data.categories.map((item, index) => {
                                    return (
                                      <small key={`tag-${index}`}>
                                        <Link to={`/${item.category.uid}/`}>
                                          #{item.category.document[0].data.name}
                                        </Link>
                                      </small>
                                    );
                                  })}
                                  {data.year && (
                                    <small>
                                      <Link to={`/works/${data.year.uid}/`}>
                                        #{data.year.uid}
                                      </Link>
                                    </small>
                                  )}
                                </span>

                                {!this.props.isOrdered && JSON.parse(data.available) && (
                                  <AddToCart>
                                    {JSON.parse(data.available) && !this.props.isOrdered && (
                                      <React.Fragment>
                                        <div className="price">
                                          {
                                            isFondAtelier ? (
                                              <React.Fragment>{data.price}.00 € </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                {parseInt(data.price)}.00 €
                                              </React.Fragment>
                                            )
                                          }
                                          {` `}<small>(Shipping fee included)</small>
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {
                                      data.drouot_estimate && (
                                        <span className="drouot-estimate">
                                          (<strong>Drouot estimate :</strong> {data.drouot_estimate} €)
                                        </span>
                                      )
                                    }
                                  </AddToCart>
                                )}
                              </div>
                            </ProductInfos>
                          )
                        }
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                          !hideprice && (
                            <ProductInfos className={isOrdered ? "isOrdered" : ""}>
                              <div>
                                <h2>
                                  <div>{data.title.text}</div>
                                  {(JSON.parse(data.available) === false || isOrdered) && (
                                    <NotAvailable />
                                  )}
                                </h2>
                                <span className="type">{data.type}</span>
                                <span className="categories">
                                  <small>categories: </small>
                                  <small>
                                    <Link to={`/works/`}>#works</Link>
                                  </small>
                                  {data.categories.map((item, index) => {
                                    return (
                                      <small key={`tag-${index}`}>
                                        <Link to={`/${item.category.uid}/`}>
                                          #{item.category.document[0].data.name}
                                        </Link>
                                      </small>
                                    );
                                  })}
                                  {data.year && (
                                    <small>
                                      <Link to={`/works/${data.year.uid}/`}>
                                        #{data.year.uid}
                                      </Link>
                                    </small>
                                  )}
                                </span>

                                {!this.props.isOrdered && JSON.parse(data.available) && (
                                  <AddToCart>
                                    {JSON.parse(data.available) && !this.props.isOrdered && (
                                      <React.Fragment>
                                        <div className="price">
                                          {
                                            isFondAtelier ? (
                                              <React.Fragment>{data.price}.00 € </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                {parseInt(data.price)}.00 €
                                              </React.Fragment>
                                            )
                                          }
                                          {` `}<small>(Shipping fee included)</small>
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {
                                      data.drouot_estimate && (
                                        <span className="drouot-estimate">
                                          (<strong>Drouot estimate :</strong> {data.drouot_estimate} €)
                                        </span>
                                      )
                                    }
                                  </AddToCart>
                                )}
                              </div>
                            </ProductInfos>
                          )
                        }

                        <ImageContainer className="full">
                          <ShopImageWrapper>
                            <img 
                              className={`xzoom${responsiveHeight ? ' responsiveHeight' : ''}`}
                              src={data.cadre.localFile ? (
                                data.cadre.localFile.childImageSharp.fluid.src
                              ) : (
                                data.item.localFile.childImageSharp.fluid.src
                              )}
                              xoriginal={data.image_hd.localFile ? (
                                data.image_hd.localFile.childImageSharp.original.src
                              ) : (
                                data.item.localFile.childImageSharp.fluid.src
                              )}
                            />
                          </ShopImageWrapper>
                        </ImageContainer>
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              )}
            </ProductInner>
          </ProductWrapper>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  firebaseOrders: state.order.items,
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(CatalogItem);
